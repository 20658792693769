@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
  font-family: 'Poppins',sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-custom {
  color: white;
  background-color: rgb(249, 177, 49);
}

.navbar-custom a:hover {
  color: white;
}

footer a {
  text-decoration: none;
  color: white;
}

footer a:hover {
  color: rgb(249, 177, 49);
}

footer span {
  margin-bottom: 10px;
}

#hubungi-kami {
  color: rgb(12, 46, 122);
  font-size: 25px;
}
#hubungi-kami:hover {
  color: white;
}

.custom-footer-up {
  padding: 2px;
  border: 2px solid rgb(249, 177, 49);
  border-radius: 15px 15px 0 0;
  background-color: rgb(249, 177, 49);
  color: rgb(12, 46, 122);
  font-weight: bold;
}

.custom-footer-down {
  padding: 5px;
  border: 2px solid rgb(249, 177, 49);
  border-radius: 0px 0px 10px 10px;
  background-color: rgb(12, 46, 122);
  color: white;
}

.copyright {
  color: rgb(249, 177, 49);
  font-size: 15px;
}

.visi-about {
  background-color: rgb(249, 177, 49);
  min-height: 35vh;
  color: white;
}

.visi-about h5,
.video-about h1 {
  color: rgb(12, 46, 122);
  text-transform: uppercase;
  font-weight: 750;
}

.video-about {
  min-height: 50vh;
}

.product,
.linimasa-about,
.direksi-about,
.maps-hero {
  background-color: rgb(228, 229, 230);
}

.btn-product {
  text-decoration: none;
  border: solid rgb(12, 46, 122);
  background-color: rgb(228, 229, 230);
  border-radius: 10px 10px 10px 10px;
  color: rgb(12, 46, 122);
  font-size: 25px;
}

.info-perusahaan a {
  text-decoration: none;
  background-color: white;
  border-radius: 10px 10px 10px 10px;
  border: solid rgb(12, 46, 122);
  color: rgb(12, 46, 122);
  font-size: 15px;
}

.info-perusahaan a:hover {
  color: white;
  background-color: rgb(12, 46, 122);
}

.btn-product:hover {
  color: white;
  background-color: rgb(12, 46, 122);
  border: rgb(12, 46, 122) solid;
}

.produk-card {
  background-color: white;
  border-radius: 10px 10px 10px 10px;
}

.produk-card:hover {
  background-color: rgb(249, 177, 49);
}

.nilai-card h4 {
  color: rgb(12, 46, 122);
}

.custom-footer i {
  font-size: 25px;
  color: rgb(249, 177, 49);
}

.misi-about h5,
.lokasi-about h5,
.nilai h5,
.linimasa-about h5,
.direksi-about h5,
.kontak h5,
.maps-hero h5 {
  border-radius: 10px 10px 10px 10px;
  color: rgb(12, 46, 122);
  border: rgb(12, 46, 122) solid;
  font-size: 25px;
  font-weight: 500;
  width: 70%;
}

.maps-hero h5:hover {
  background-color: rgb(12, 46, 122);
  color: white;
}

.kontak h5:hover {
  background-color: rgb(12, 46, 122);
  color: white;
}
.nilai h5:hover {
  background-color: rgb(12, 46, 122);
  color: white;
}
.direksi-about h5:hover {
  background-color: rgb(12, 46, 122);
  color: white;
}
.linimasa-about h5:hover {
  background-color: rgb(12, 46, 122);
  color: white;
}

.misi-about h5:hover {
  background-color: rgb(12, 46, 122);
  color: white;
}
.lokasi-about h5:hover {
  background-color: rgb(12, 46, 122);
  color: white;
}

.custom-card {
  border-radius: 10px 10px 10px 10px;
  border: rgb(249, 177, 49) solid;
}
.card-title {
  color: rgb(12, 46, 122);
  font-weight: bold;
}

.button-selengkapnya {
  border: rgb(12, 46, 122) solid;
  border-radius: 10px 10px 10px 10px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: rgb(12, 46, 122);
  font-weight: 500;
  font-size: 15px;
  background-color: white;
  margin-bottom: 10px;
}

.button-selengkapnya:hover {
  background-color: rgb(12, 46, 122);
  color: white;
}
.modal-header {
  background-color: rgb(249, 177, 49);
  border-radius: 30px 30px 0px 0px;
}
.modal-content {
  border-radius: 30px 30pz;
}

.product-section {
  height: 100vh;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: rgb(249, 177, 49);
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.nav-link {
  margin-left: 10px;
  margin-right: 10px;
}
.nav-link a{
  font-weight: bold;
}

.kontak-card {
  font-size: 50px;
  font-weight: 500;
  color: rgb(249, 177, 49);
}

.kontak-card:hover {
  color: rgb(12, 46, 122);
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* Ratio 16:9 ( 100%/16*9 = 56.25% ) */
}
.iframe-container > * {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.maps {
  background-color: rgb(249, 177, 49);
}
.carousel img {
  margin: auto;
}

.navbar-custom a{
  font-weight: 500;
  color: black;
}


#contact-link{
  background-color: rgb(249, 177, 49);
  border: rgb(12, 46, 122) solid 2px;
  border-radius: 10px 10px 10px 10px;
  color: rgb(12, 46, 122);
}

#contact-link:hover{
  background-color: white;
  border: white solid 2px;
  color:rgb(12, 46, 122);
}

.download{
  border: 2px rgb(249, 177, 49) solid;
  border-radius: 10px 10px 10px 10px;
  padding-bottom: 0;
}

.button-download{
  background-color: rgb(249, 177, 49);
  text-decoration: none;
  color: white;
  border-radius: 0px 0px 10px 10px;
  border: 2px rgb(249, 177, 49) solid;
}

.button-download:hover{
  color: white;
}

#language{
  border: none;
}

#struktur{
  padding: 0;
}